// Core version + navigation modules:
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

// Import Swiper and modules styles (if any)
import 'swiper/css';

/**
 * Initializes a Swiper instance on the specified container.
 * This function sets up a Swiper slider with custom navigation buttons and configurable slide views.
 *
 * @param {HTMLElement} containerElement - The container where the Swiper instance will be initialized.
 * The Swiper instance will use elements inside this container for its slides and navigation buttons.
 */
export function initSwiper(containerElement) {
    const swiperContainer = containerElement.querySelector('.swiper');
    const nextEl = containerElement.querySelector('.swiper-button-next');
    const prevEl = containerElement.querySelector('.swiper-button-prev');

    new Swiper(swiperContainer, {
        modules: [ Navigation ],
        slidesPerView: 'auto',
        spaceBetween: 16,
        navigation: {
            nextEl: nextEl,
            prevEl: prevEl
        },
    });
}